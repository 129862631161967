/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { css, jsx, keyframes  } from '@emotion/core'
import Cookies from 'universal-cookie';

const slidein = keyframes`
  from {
    transform: translateY(150vh);
  }

  to {
    transform: translateY(0vw);
  }
`

const MailchimpSlideIn = styled.div`
    transform: translateX(150vw);
    display: none;
    animation: ${slidein} 1s 2s ease forwards;
    &.disabled{
        display: none;
    }
    &.nl-false{
        display: none;
    }

    z-index:999;
    background-color: ${props => props.theme.colors.grey};
    max-width: 700px;

    position: fixed;
    bottom: 0;
    right: 0;
    @media (min-width: ${props => props.theme.responsive.desktop}) {
        right: calc(50% - 1404px / 2);
    }

    * {
        font-family: inherit;
    }

    label {
        display: none;
    }

    .cr_ipe_item {
        width: auto;
        display: inline-block;
    }

    .editable_content {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        flex-flow: column;
        }

        @media (max-width: ${props => props.theme.responsive.smallMax}) {
        width: 100%;
        }
    }


    #mce-EMAIL {
        width: 70%;
    }

    #mc-embedded-subscribe {
        width: 30%;
    }

    #mc_embed_signup_scroll {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .cr_button,
    #mc-embedded-subscribe {
        cursor: pointer;
        border-radius: 0 !important;
    }

    .cr_button:hover,
    #mc-embedded-subscribe:hover {
        background: ${props => props.theme.colors.text};
        color: ${props => props.theme.colors.background};
    }

    input,
    .cr_button,
    #mce-EMAIL,
    #mc-embedded-subscribe {
        max-width: 100%;
        color: ${props => props.theme.colors.text};
        border-bottom: 2px solid ${props => props.theme.colors.text};
        height: 70px;
        line-height: 70px;
        padding: 0 14px;
        font-size: ${props => props.theme.fontSizes[5]}px;
        border-radius: 0px;
        padding: 0 20px;

        @media (max-width: ${props => props.theme.responsive.largeMax}) {
        font-size: ${props => props.theme.fontSizes[3]}px;
        margin-top: 20px;
        }

        @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        margin-top: 15px;
        padding: 0 15px;
        line-height: 50px;
        height: 50px;
        font-size: ${props => props.theme.fontSizes[2]}px;
        }
        @media (max-width: ${props => props.theme.responsive.smallMax}) {
        margin-top: 10px;
        padding: 0 10px;
        line-height: 40px;
        height: 40px;
        font-size: ${props => props.theme.fontSizes[2]}px;
        }
    }
    
`

const Addi = styled.div`
    background-color: ${props => props.theme.colors.purple};
    width: 100%;
    padding: 20px 30px;
    font-size: ${props => props.theme.fontSizes[5]}px;
    line-height: 1.4;
    color: #fff;
    span{
        color: ${props => props.theme.colors.black};
    }
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        font-size: ${props => props.theme.fontSizes[1]}px;
        padding: 10px 30px;
    }
`

const Content = styled.div`
    background-color: ${props => props.theme.colors.grey};
    width: 100%;
    padding: 20px 30px;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        padding: 10px 30px;
    }
`

const Optin = styled.div`
  display: inline-block;
  width: 100%;
`

const Disclaimer = styled.div`
  font-size: 0.8rem;
  margin-top: 10px;

  a {
    text-decoration: underline;
  }
`

const Headline = styled.div`
    text-transform: uppercase;
    font-size: ${props => props.theme.fontSizes[8]}px;
    line-height: 1;

    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        font-size: ${props => props.theme.fontSizes[3]}px;
        line-height: 1.1;
    }
`


const Description = styled.div`
    columns: 2;
    font-size: ${props => props.theme.fontSizes[5]}px;
    line-height: 1.4;
    padding: 15px 0 30px;
    color: #fff;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        font-size: ${props => props.theme.fontSizes[1]}px;
        columns: 1;
        padding: 5px 0 15px;
    }
`

const Close = styled.div`
    cursor:pointer;
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 999999;
    font-size: ${props => props.theme.fontSizes[5]}px;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
        font-size: ${props => props.theme.fontSizes[3]}px;
    }
`

class MailchimpPopupNews extends React.Component {
    constructor(props) {
        super(props)
        const cookies = new Cookies();
        this.state = {
            active: cookies.get("slidein") || "true", 
            color: this.props.color,
            disabled: "enabled"
        }
    }

    makeShow = () => {
        this.setState({ active: true })
    }

    makeHide = () => {
        const cookies = new Cookies();
        this.setState({ active: "false", disabled: "disabled" })
        cookies.set("slidein", "false", {path: "/", maxAge: 31556952, secure: false, sameSite: "Lax", httpOnly: false});
    }

    handleSubmit = event => {
        const cookies = new Cookies();
        this.setState({ active: "false", disabled: "disabled" })
        cookies.set("slidein", "false", {path: "/", maxAge: 31556952, secure: false, sameSite: "Lax", httpOnly: false});
    }

    render(){
        let color = this.state.color;
        let text = 'Unser Newsletter macht Sie nicht nur überglücklich, sondern hält Sie auch in alltäglichen IT–Fragen auf dem Laufenden.'
        
        let bgcolor = ''
        if(color === 'news'){
            text = 'Verpassen Sie nie mehr einen unserer Beiträge aus der Welt der Computer und der Menschen, die sie bedienen.'
            bgcolor = '#bbb1f0'
        }

        return (
            <MailchimpSlideIn className={`nl-${this.state.active ? this.state.active : 'false'} ${this.state.disabled}`} css={css`
            background-color: ${bgcolor};
            `}>
                <Close onClick={this.makeHide}>(x)</Close>
                <Addi>
                    <span>Wir haben eine neue Anschrift!</span><br/>
                    &gt;&gt;&gt; Schottenfeldgasse 78<br />
                    &gt;&gt;&gt; Stg. 3 / Top 1+3 • 1070 Wien
                </Addi>
                <Content>
                <Headline>
                    News<br />
                    Freihaus
                </Headline>
                <Description>
                    {text}
                </Description>
                <Optin>
                    <div className="ark-newsletter-form">
                    <div id="mc_embed_signup">
                        <form
                        onSubmit={this.handleSubmit}
                        action="https://extendit.us2.list-manage.com/subscribe/post?u=1ec71d31edf40ac554e539f56&id=6c11d100ae"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        className="validate"
                        target="_blank"
                        >
                        <div id="mc_embed_signup_scroll">
                            <input
                            type="email"
                            name="EMAIL"
                            className="email"
                            id="mce-EMAIL"
                            placeholder="E-Mail-Adresse"
                            required
                            />
                            <div
                            className="mc-field-group input-group"
                            css={css`
                                display: none !important;
                            `}
                            >
                            <ul>
                                <li>
                                <input
                                    type="checkbox"
                                    value="1"
                                    name="group[83558][1]"
                                    id="mce-group[83558]-83558-0"
                                    defaultChecked
                                />
                                </li>
                            </ul>
                            </div>

                            <div
                            style={{ position: 'absolute', left: '-5000px' }}
                            aria-hidden="true"
                            >
                            <input
                                type="text"
                                name="b_1ec71d31edf40ac554e539f56_6c11d100ae"
                                tabIndex={-1}
                            />
                            </div>
                            <input
                            type="submit"
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            className="button"
                            value="OK"
                            />
                        </div>
                        </form>
                    </div>
                    </div>
                </Optin>
                <Disclaimer>
                    Wir verarbeiten Ihre Daten gemäß{' '}
                    <a href="/datenschutz" target="_blank" rel="noopener">
                    Datenschutzbestimmungen
                    </a>
                    .
                </Disclaimer>
                </Content>
            </MailchimpSlideIn>
        )
    }
}
export default MailchimpPopupNews
